import React from "react";
import LayoutDay from "../../../components/Chronology/LayoutDay";
import ContentLeft from "../../../components/Chronology/Modules/ContentLeft";
import ContentRight from "../../../components/Chronology/Modules/ContentRight";
import ModCCAATable from "../../../components/Chronology/Modules/ModCCAATable";
import ModText from "../../../components/Chronology/Modules/ModText";
import ModImage from "../../../components/Chronology/Modules/ModImage";
import ModDailyHighlight from "../../../components/Chronology/Modules/ModDailyHighlight";
import ModDataEu from "../../../components/Chronology/Modules/ModDataEu";
import ModLethalityEu from "../../../components/Chronology/Modules/ModLethalityEu";
import ModDottedLine from "../../../components/Chronology/Modules/ModDottedLine";
import InlineLink from "../../../components/Chronology/Modules/InlineLink";
import ModTwoCols from "../../../components/Chronology/Modules/ModTwoCols";

export const frontmatter = {
  title: "Día 81",
  week: "Semana 12",
  day: "02",
  month: "Jun",
  monthNumber: "06",
  date: "2020-06-02",
  path: "/cronologia/semana-12#dia-02-jun/",
};

const Day81 = (props) => {
  return (
    <LayoutDay frontmatter={frontmatter} {...props}>
      <ContentLeft>
        <ModCCAATable fecha={frontmatter.date} />
        <ModDottedLine />
        <ModDataEu fecha={frontmatter.date} />
        <ModLethalityEu fecha={frontmatter.date} numPaises={8} />
      </ContentLeft>

      <ContentRight>
        <ModDailyHighlight>
          Séptimo día del periodo de luto oficial
        </ModDailyHighlight>
        <ModText>
          En los últimos siete días se han diagnosticado 2,589 casos mediante
          pruebas PCR. En ese mismo periodo, 243 personas han requerido
          hospitalización (9,4% de los diagnósticos), de los que 11 han sido
          ingresos en la UCI.
        </ModText>
        <ModImage
          src="/images/svg/dialogo_presi_madrid.svg"
          alt="diálogo politico covid"
        />
        <ModText>
          El gobierno ha llegado a un acuerdo para aprobar la sexta y última
          prórroga del estado de alarma, que deberá ratificarse en el congreso.
        </ModText>
        <ModImage
          src="/images/svg/laboratorio-analisis-covid19.svg"
          alt="laboratorio analisis covid-19"
        />
        <ModText>
          A día de hoy, se ha conseguido que el
          <strong> tiempo medio de diagnóstico</strong> de casos de COVID-19 sea
          de 48 horas, desde el inicio de los síntomas, y de 24 horas, desde el
          inicio de consulta. Esto permite actuar sobre los brotes más rápido y
          de forma muy delimitada, sin tener que afectar a una gran parte del
          territorio.
        </ModText>

        <ModText>
          Por otro parte, también ha distribuido en los últimos días más de 3,8
          millones de mascarillas quirúrgicas según las necesidades de las
          CC.AA. Otros 3 millones más han sido destinadas a Cruz Roja, Caritas y
          el Comité Español de Representantes de Personas con Discapacidad, para
          repartir entre colectivos vulnerables.
        </ModText>
        <ModText>
          Con este último reparto, ya son más de{" "}
          <strong>117 millones de mascarillas</strong>
          repartidas desde el inicio de la crisis.
        </ModText>
        <ModTwoCols
          src="/images/svg/img-airport-planes.svg"
          alt="transporte aéreo"
          small={false}
          inverted={false}
        >
          En materia de servicios aéreos y marítimos de viajeros, el Gobierno ha
          publicado las{" "}
          <InlineLink link="https://www.boe.es/eli/es/o/2020/06/01/snd487/con">
            Text content
          </InlineLink>{" "}
          condiciones a aplicar durante las fases 2 y 3 del Plan de desescalada,
          y ha habilitado instrumentos que permitan ir adaptando la oferta de
          los servicios a la evolución de la demanda y de la situación
          sanitaria.
        </ModTwoCols>
      </ContentRight>
    </LayoutDay>
  );
};
export default Day81;
